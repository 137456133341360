import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import Quote from '@components/IndustryDataType/Quote';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Defense_Cover.jpg';
import LockheedMartinLogo from './assets/Lockheed_Martin.png';
import metaImage from './assets/meta.jpg';

const DefensePage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="industry-defense"
      helmet={{
        title: 'TileDB for Defense',
        description: 'TileDB is the universal, secure and scalable database t o support all mission data and analytics.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="Defense"
      title="Embrace a secure and scalable database to support all mission data and analytics"
      heroImage={BackgroundImage}
      icon={industries.defense.icon}
      overview={
        <>
          <p>
            Data and analytics have always been a critical foundation in defense missions from electronic warfare, C4ISR and autonomous
            systems to a variety of remote sensing systems.
          </p>
          <p>
            The TileDB Cloud universal database is uniquely suited to complex and vast data environments - able to handle efficient
            management and scalable analytics for all data types from geospatial, imagery and tables to flat files in a secure and
            compliant, isolated on-premises deployment.
          </p>
        </>
      }
      relatedItems={[dataTypes.lidar, dataTypes.timeSeries, dataTypes.sar, dataTypes.weather]}
    >
      <Quote
        logo={<img src={LockheedMartinLogo} style={{ width: 336 }} alt="Lockheed martin" />}
        author={{ name: 'Chris Moran', title: 'Vice President and General Manager' }}
        text="As the volume and velocity of data critical to our customers and industry partners increases, Lockheed Martin recognizes the crucial role that advanced data management systems play in this evolution. We’re excited to invest in TileDB who is pioneering solutions for complex data access and analysis needs."
      />
    </IndustryDataTypeTemplate>
  );
};

export default DefensePage;
